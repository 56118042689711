/* eslint-disable react/display-name */
import React, { useEffect, Dispatch, SetStateAction } from "react"
import SubHead from "../components/subHead"
import Footer from "../components/footer"
import "./index.scss"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

// eslint-disable-next-line react/display-name
export default ({
  setMenuType,
  menuStatus,
}: {
  setMenuType: Dispatch<SetStateAction<string>>
}): React.ReactNode => {
  useEffect(() => {
    const options = {
      threshold: 0.55,
    }

    if (!menuStatus.menuOpen) {
      const observer = new IntersectionObserver(element => {
        if (element[0].isIntersecting) {
          const color = element[0].target.getAttribute("data-color")

          if (color) {
            setMenuType(color)
          }
        } else if (element[0].target.id === "about_page_hero") {
          setMenuType("dark")
        }
      }, options)

      document
        .querySelectorAll(".about_page_ovserver_target")
        .forEach(entry => {
          observer.observe(entry)
        })

      return (): void => {
        observer.disconnect()
      }
    }
  }, [menuStatus.menuOpen])

  return (
    <StaticQuery
      query={graphql`
        query MyQuery {
          contentfulAbout {
            techilike {
              json
            }
            summary {
              json
            }
            notcode {
              json
            }
            looking {
              json
            }
            aboutpageplaceholder {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      `}
    >
      {({ contentfulAbout }) => {
        return (
          <>
            <div className="about_page">
              <div
                id="about_page_hero"
                className="about_content about_page_ovserver_target "
              >
                <SubHead mode="dark">About Me</SubHead>
              </div>
            </div>
            <Img
              className="about_img"
              fluid={contentfulAbout.aboutpageplaceholder.fluid}
            />
            <h2 className="about_page_summary ">
              {documentToReactComponents(contentfulAbout.summary.json, {
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (_, children): React.ReactNode => (
                    <p className="about_page_summary_text">{children}</p>
                  ),
                },
              })}
            </h2>
            <div>
              <div
                data-color="light"
                className="about_page_row about_page_ovserver_target"
              >
                <div className="about_page_row_content">
                  <div className="about_page_row_text_wrapper">
                    <h3 className="about_page_row_header">Looking for</h3>
                    {documentToReactComponents(contentfulAbout.looking.json, {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (_, children): React.ReactNode => (
                          <p className="about_page_row_text">{children}</p>
                        ),
                      },
                    })}
                  </div>
                  <svg
                    className="about_page_row_svg .reveal_svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 1000 1000"
                  >
                    <g>
                      <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                        <path
                          className="reveal_svg_path"
                          stroke="#0000bb"
                          fill="none"
                          strokeWidth="5"
                          d="M2052,4947.8c-34.3-31.9-61.3-78.5-61.3-103c0-24.5,188.9-701.5,419.5-1503.7C2640.7,2539,3148.5,772.8,3538.5-583.7c392.5-1356.5,816.8-2835.6,944.4-3287c147.2-510.2,252.6-838.9,282.1-870.8c66.2-68.7,211-63.8,269.8,9.8c27,31.9,46.6,80.9,46.6,110.4c0,41.7-179.1,696.6-257.6,944.4l-14.7,51.5h1270.6h1273.1l152.1-532.3c85.9-294.4,166.8-549.4,184-566.6c83.4-103,262.5-76.1,311.5,46.6c27,63.8,4.9,161.9-240.4,1010.6c-147.2,515.1-762.9,2654.1-1366.3,4751.4c-1175,4089.1-1121,3924.7-1278,3924.7c-78.5,0-181.5-103-181.5-179.1c0-44.2,147.2-588.7,260-954.2c12.3-41.7-58.9-44.2-1258.4-44.2H2662.8l-144.7,510.2c-169.2,596.1-171.7,603.4-250.2,640.2C2177.1,5023.8,2115.8,5014,2052,4947.8z M5446.9,2992.7c71.1-247.7,132.4-468.5,139.8-490.6c12.3-41.7-58.9-44.2-1258.4-44.2H3057.7l-90.8,326.2c-51.5,176.6-112.8,397.4-139.8,490.6l-49.1,164.4h1270.6h1270.6L5446.9,2992.7z M5729,2023.8c68.7-235.5,260-914.9,260-924.8c0-9.8-571.5-12.3-1268.2-9.8l-1268.2,7.4l-103,355.7c-56.4,196.2-120.2,414.5-139.8,485.7l-39.2,127.6h1273.1C5633.3,2065.5,5716.7,2063.1,5729,2023.8z M6158.3,527.5c112.8-382.6,223.2-780,223.2-797.2c0-12.3-569.1-17.2-1265.7-14.7L3850-277l-142.3,485.7l-142.3,483.2h1273.1h1270.6L6158.3,527.5z M6563-882.9c115.3-385.1,210.9-726.1,210.9-753.1c0-19.6-360.6-27-1260.8-27H4252.3l-36.8,117.7c-73.6,223.2-237.9,809.5-237.9,836.5c0,19.6,345.9,27,1260.8,27h1263.3L6563-882.9z M7070.7-2644.1C7164-2960.6,7240-3223,7240-3225.5c0-4.9-571.5-7.4-1273.1-7.4H4696.3l-164.3,571.5c-88.3,314-161.9,578.9-161.9,588.7c0,9.8,569.1,14.7,1265.7,12.3l1268.2-7.4L7070.7-2644.1z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <div
                data-color="dark"
                className="about_page_row about_page_ovserver_target"
              >
                <div className="about_page_row_content">
                  <div className="about_page_row_text_wrapper">
                    <h3 className="about_page_row_header">Tech I like</h3>
                    {documentToReactComponents(contentfulAbout.techilike.json, {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (_, children): React.ReactNode => (
                          <p className="about_page_row_text">{children}</p>
                        ),
                      },
                    })}
                  </div>
                  <svg
                    className="about_page_row_svg .reveal_svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1257.000000 1280.000000"
                  >
                    <g
                      transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                      fill="none"
                      stroke="#eff1f3"
                    >
                      <path
                        className="reveal_svg_path"
                        stroke="#eff1f3"
                        fill="none"
                        strokeWidth="10"
                        d="M7484 12789 c-67 -19 -123 -121 -109 -198 15 -81 61 -135 125 -148
l30 -6 -2 -2120 -3 -2121 -47 3 -48 3 0 -166 0 -166 -195 0 -195 0 -2 163 -3
162 -55 1 -55 1 -3 1128 c-2 1114 -2 1127 18 1152 27 35 43 99 35 142 -10 57
-50 116 -91 136 -49 23 -148 17 -186 -10 -65 -48 -90 -154 -55 -236 20 -50 76
-90 132 -97 l45 -5 -2 -1104 -3 -1105 -85 -2 -85 -1 -3 -162 -2 -163 -195 0
-194 0 0 163 0 162 -83 0 -83 0 -5 720 -5 720 -542 3 -543 2 0 755 0 755 28
42 c23 36 27 52 27 113 0 63 -3 76 -28 108 -38 50 -75 67 -145 67 -45 0 -67
-6 -93 -23 -67 -44 -98 -153 -65 -230 17 -41 76 -93 115 -102 l31 -7 0 -789 0
-789 555 0 555 0 0 -672 0 -673 -52 0 -53 0 -3 -162 -2 -163 -195 0 -195 0 -2
163 -3 162 -48 0 -47 0 0 418 0 417 -633 0 -634 0 -24 26 c-37 40 -73 54 -137
54 -45 0 -67 -5 -93 -23 -110 -74 -104 -253 11 -314 109 -58 238 3 257 122 l6
35 553 0 554 0 -2 -366 -3 -367 -70 -1 -70 -1 -3 -162 -2 -163 -195 0 -195 0
-2 163 -3 162 -30 0 -30 1 -3 182 -2 182 -1093 0 -1092 0 -50 26 c-123 63
-255 -19 -255 -159 0 -62 19 -105 63 -144 93 -82 246 -32 279 91 l12 46 1003
0 1004 0 -3 -111 -3 -111 -95 -1 -95 -2 -3 -162 -2 -163 -240 0 -240 0 0 -345
0 -345 -165 0 -165 0 0 -40 0 -40 -1038 0 -1039 0 -33 41 c-94 117 -272 81
-310 -63 -21 -77 10 -159 79 -205 26 -17 48 -23 91 -23 67 0 111 25 149 83
l24 37 1039 0 1038 0 0 -90 0 -90 165 0 165 0 0 -195 0 -195 -165 0 -165 0 0
-70 0 -70 -1610 0 -1610 0 0 930 0 930 25 16 c41 27 68 96 63 165 -5 62 -23
97 -69 132 -56 41 -152 41 -208 0 -58 -42 -85 -139 -60 -214 13 -38 63 -96 91
-105 17 -5 18 -51 20 -997 l3 -992 1677 -3 1678 -2 0 -60 0 -60 165 0 165 0 0
-195 0 -195 -164 0 -165 0 -3 -47 -3 -48 -1037 -3 -1036 -2 -31 -39 -31 -39 0
-740 0 -740 -35 -6 c-110 -21 -168 -180 -101 -278 35 -51 70 -71 134 -76 100
-7 158 35 184 133 13 50 0 125 -28 156 -12 13 -14 125 -14 753 0 489 -3 744
-10 757 -11 19 4 19 1000 19 l1010 0 0 -95 0 -95 165 0 165 0 0 -285 0 -285
300 0 300 0 0 -165 0 -165 40 0 40 0 0 -779 c0 -772 0 -779 -21 -805 l-20 -26
-657 0 -657 1 -20 31 c-30 48 -78 71 -145 71 -104 0 -173 -71 -174 -178 -1
-197 249 -255 334 -78 l25 53 674 0 673 0 59 51 59 51 0 804 0 804 90 0 90 0
0 165 0 165 195 0 195 0 0 -161 c0 -152 1 -161 20 -166 20 -5 20 -14 20 -1818
l0 -1814 -26 -20 c-26 -21 -36 -21 -550 -21 -330 0 -524 4 -524 10 0 18 -65
83 -97 96 -80 34 -178 2 -221 -71 -33 -58 -28 -157 11 -209 41 -53 78 -69 151
-64 74 4 116 30 143 88 l18 39 541 1 541 0 74 53 74 53 3 1837 2 1836 108 3
107 3 3 163 2 162 195 0 195 0 0 -165 0 -165 50 0 50 0 0 -1520 0 -1519 -42
-12 c-110 -29 -154 -161 -94 -277 32 -62 117 -93 197 -72 116 31 163 186 88
291 -18 26 -19 71 -19 1568 l0 1541 80 0 80 0 0 165 0 165 195 0 195 0 0 -165
0 -165 80 0 80 0 0 -1995 c0 -1961 0 -1994 -19 -2005 -13 -6 -241 -10 -671
-10 -647 0 -651 0 -679 21 -78 57 -194 37 -255 -44 -32 -43 -34 -150 -4 -202
41 -71 143 -104 221 -71 44 18 95 78 103 121 l6 35 650 0 651 0 68 56 69 56 0
2019 0 2019 45 0 45 0 0 165 0 165 195 0 195 0 2 -163 3 -162 195 0 195 0 3
163 2 162 195 0 195 0 0 -165 0 -165 195 0 195 0 0 165 0 165 195 0 195 0 2
-162 3 -163 192 -3 193 -2 2 162 3 163 393 3 392 2 0 490 0 490 160 0 160 0 0
60 0 60 660 0 660 0 0 -770 0 -770 -39 -24 c-77 -49 -103 -161 -58 -247 58
-109 218 -122 293 -23 26 34 29 45 29 114 0 69 -3 80 -29 114 -16 21 -44 44
-62 52 l-34 14 -2 838 -3 837 -707 3 -708 2 0 68 0 67 -160 -3 -160 -3 0 201
0 200 160 0 159 0 3 88 3 87 801 3 801 2 18 -41 c61 -134 254 -130 316 6 65
143 -52 291 -201 255 -44 -11 -110 -59 -110 -81 0 -5 -344 -8 -812 -7 l-813 3
-3 38 -3 37 -160 0 -159 0 0 530 0 530 -450 0 -450 0 -2 163 c-2 123 -6 161
-15 160 -10 -2 -13 184 -13 907 l0 910 900 0 899 0 19 -43 c28 -63 72 -91 152
-95 55 -3 70 0 99 20 62 42 76 70 76 156 0 90 -21 129 -85 162 -70 36 -149 24
-205 -31 l-31 -29 -964 -2 -965 -3 -3 -975 -2 -975 -125 3 -125 3 0 -165 0
-166 -197 0 -198 0 0 163 0 162 -45 1 -45 1 -3 1581 -2 1582 727 2 727 3 -3
446 -3 446 34 24 c46 33 65 58 78 106 31 109 -40 220 -148 231 -65 6 -114 -11
-151 -53 -79 -90 -56 -233 46 -287 l43 -23 0 -398 0 -399 -740 3 -740 2 -3
-1632 -2 -1633 -80 -1 -80 -2 -3 -162 -2 -163 -195 0 -195 0 -2 163 -3 162
-73 1 -72 2 0 2137 c0 2120 0 2137 20 2165 31 44 44 117 31 168 -28 103 -126
153 -237 121z"
                      />
                      <path
                        stroke="#eff1f3"
                        strokeWidth="10"
                        className="reveal_svg_path"
                        d="M7670 4298 c0 -2 -1 -621 -3 -1377 l-2 -1374 -44 -13 c-80 -23 -123
-83 -124 -173 0 -77 32 -134 95 -167 39 -20 122 -18 167 6 98 49 120 206 41
287 l-30 31 -2 1388 -3 1389 -47 3 c-27 2 -48 2 -48 0z"
                      />
                      <path
                        stroke="#eff1f3"
                        strokeWidth="10"
                        className="reveal_svg_path"
                        d="M8510 2775 l0 -1525 873 0 872 -1 20 -41 c31 -65 63 -83 150 -83 59
0 79 4 102 21 104 77 95 255 -17 312 -25 12 -62 22 -85 22 -51 -1 -119 -31
-140 -64 l-17 -26 -809 0 -809 0 -2 1453 -3 1452 -67 3 -68 3 0 -1526z"
                      />
                      <path
                        stroke="#eff1f3"
                        strokeWidth="10"
                        className="reveal_svg_path"
                        d="M9255 4288 c-3 -7 -4 -495 -3 -1083 l3 -1070 932 -5 933 -5 22 -45
c33 -65 76 -90 153 -90 68 0 110 21 149 73 32 43 34 150 4 202 -41 71 -143
104 -221 71 -32 -13 -97 -78 -97 -96 0 -6 -323 -10 -885 -10 l-885 0 -2 1033
-3 1032 -48 3 c-32 2 -49 -1 -52 -10z"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div
                data-color="light"
                className="about_page_row about_page_ovserver_target"
              >
                <div className="about_page_row_content">
                  <div className="about_page_row_text_wrapper">
                    <h3 className="about_page_row_header">
                      When I&apos;m not coding
                    </h3>
                    {documentToReactComponents(contentfulAbout.notcode.json, {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (_, children): React.ReactNode => (
                          <p className="about_page_row_text">{children}</p>
                        ),
                      },
                    })}
                  </div>
                  <svg
                    className="about_page_row_svg .reveal_svg"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 550.752 550.752"
                  >
                    <path
                      className="reveal_svg_path"
                      stroke="#0000bb"
                      strokeWidth="1"
                      fill="none"
                      d="M248.863,422.497c0.047-0.002,0.094-0.006,0.14-0.009c42.007-2.143,81.195-19.517,111.155-49.477
	c30.957-30.957,48.477-71.767,49.64-115.358c0.003-0.096,0.008-0.192,0.011-0.289c0.036-1.449,0.056-2.901,0.056-4.355
	s-0.02-2.906-0.056-4.355c-0.003-0.097-0.008-0.193-0.011-0.29c-0.31-11.612-1.787-23.024-4.364-34.108
	c3.617,0.5,7.275,0.764,10.957,0.764c43.746,0,79.336-35.59,79.336-79.335c0-12.995-3.213-25.888-9.292-37.285
	c-1.949-3.655-6.493-5.037-10.147-3.088c-3.655,1.949-5.038,6.492-3.088,10.146c4.924,9.232,7.527,19.685,7.527,30.227
	c0,35.475-28.861,64.335-64.336,64.335c-5.284,0-10.504-0.648-15.571-1.907c-0.667-1.957-1.363-3.904-2.101-5.833l10.694-24.087
	l5.52,2.547c1.018,0.469,2.085,0.691,3.137,0.691c2.835,0,5.549-1.616,6.815-4.359l20.898-45.293
	c0.833-1.807,0.915-3.87,0.227-5.736c-0.688-1.866-2.089-3.383-3.895-4.217l-24.264-11.195c-3.762-1.732-8.217-0.093-9.952,3.668
	L387,149.592c-0.833,1.807-0.915,3.87-0.227,5.736c0.688,1.866,2.089,3.383,3.895,4.217l5.123,2.364l-5.364,12.081
	c-7.865-14.912-18.009-28.721-30.27-40.982c-2.399-2.399-4.865-4.708-7.38-6.944C357.44,94.62,384.15,71.35,416.392,71.35
	c11.212,0,22.248,2.925,31.913,8.459c3.595,2.058,8.177,0.813,10.235-2.782c2.058-3.595,0.813-8.177-2.782-10.235
	c-11.93-6.831-25.542-10.441-39.366-10.441c-19.927,0-38.979,7.418-53.647,20.888c-11.31,10.386-19.248,23.668-23.077,38.27
	c-26.413-19.2-57.666-30.292-90.665-31.975c-0.047-0.002-0.094-0.006-0.14-0.009c-1.247-0.063-2.498-0.107-3.75-0.143
	c-0.207-0.006-0.413-0.017-0.62-0.022c-1.442-0.036-2.887-0.055-4.335-0.055s-2.893,0.019-4.335,0.055
	c-0.207,0.005-0.413,0.017-0.62,0.022c-1.252,0.036-2.503,0.081-3.75,0.143c-0.047,0.002-0.094,0.006-0.14,0.009
	c-42.006,2.143-81.195,19.517-111.155,49.476c-29.959,29.96-47.334,69.148-49.477,111.154c-0.002,0.047-0.006,0.094-0.009,0.142
	c-0.063,1.247-0.107,2.497-0.143,3.749c-0.006,0.208-0.018,0.414-0.023,0.622c-0.036,1.442-0.055,2.887-0.055,4.335
	s0.019,2.893,0.055,4.335c0.005,0.207,0.017,0.413,0.023,0.62c0.036,1.252,0.08,2.503,0.143,3.751
	c0.002,0.046,0.006,0.091,0.008,0.137c2.142,42.008,19.517,81.198,49.477,111.158c29.96,29.96,69.149,47.334,111.155,49.477
	c0.047,0.002,0.094,0.006,0.14,0.009c1.247,0.063,2.498,0.107,3.75,0.143c0.207,0.006,0.413,0.017,0.62,0.022
	c1.442,0.036,2.887,0.055,4.335,0.055s2.893-0.019,4.335-0.055c0.207-0.005,0.413-0.017,0.62-0.022
	C246.365,422.604,247.615,422.56,248.863,422.497z M418.376,117.394l10.644,4.911l-14.614,31.673l-10.644-4.911L418.376,117.394z
	 M248.093,407.517c-1.132,0.057-2.268,0.097-3.406,0.13c-0.194,0.005-0.386,0.016-0.58,0.021c-1.313,0.033-2.63,0.05-3.951,0.05
	s-2.638-0.017-3.951-0.05c-0.194-0.005-0.386-0.016-0.58-0.021c-1.138-0.033-2.274-0.073-3.406-0.13
	c-0.043-0.002-0.085-0.006-0.128-0.008c-78.902-4.072-142.363-67.533-146.435-146.435c-0.002-0.043-0.006-0.085-0.008-0.128
	c-0.057-1.132-0.097-2.269-0.13-3.407c-0.005-0.193-0.016-0.385-0.021-0.579c-0.033-1.313-0.05-2.63-0.05-3.951
	s0.017-2.638,0.05-3.951c0.005-0.194,0.016-0.387,0.021-0.581c0.033-1.137,0.072-2.273,0.13-3.404
	c0.002-0.043,0.006-0.085,0.008-0.128c4.072-78.901,67.533-142.362,146.435-146.434c0.043-0.002,0.085-0.006,0.128-0.008
	c1.132-0.057,2.268-0.097,3.406-0.13c0.194-0.005,0.386-0.016,0.58-0.021c1.313-0.033,2.63-0.05,3.951-0.05s2.638,0.017,3.951,0.05
	c0.194,0.005,0.386,0.016,0.58,0.021c1.138,0.033,2.274,0.073,3.406,0.13c0.043,0.002,0.085,0.006,0.128,0.008
	c60.238,3.108,111.471,40.836,134.152,93.62l-70.894,159.682l-37.978,2.629v-7.359c0-4.143-3.358-7.5-7.5-7.5h-55.946
	c-4.142,0-7.5,3.357-7.5,7.5v7.42h-12.447c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h12.447v7.42c0,4.143,3.358,7.5,7.5,7.5
	h55.946c4.142,0,7.5-3.357,7.5-7.5v-7.444l43.518-3.013c2.771-0.192,5.209-1.9,6.337-4.439l66.218-149.149
	c3.092,11.501,4.897,23.525,5.23,35.91c0.002,0.084,0.007,0.167,0.009,0.251c0.033,1.32,0.05,2.644,0.05,3.971
	c0,1.328-0.017,2.652-0.05,3.972c-0.002,0.083-0.007,0.165-0.009,0.248c-2.171,80.676-66.405,146.142-146.584,150.28
	C248.178,407.511,248.136,407.514,248.093,407.517z M217.555,369.423v-14.84h6.882v14.84H217.555z M239.438,354.583h19.064v14.84
	h-19.064V354.583z M120.218,253.01c0,4.143-3.358,7.5-7.5,7.5s-7.5-3.357-7.5-7.5c0-74.405,60.533-134.938,134.939-134.938
	c4.142,0,7.5,3.357,7.5,7.5s-3.358,7.5-7.5,7.5C174.022,133.071,120.218,186.875,120.218,253.01z M154.794,253.01
	c0,4.143-3.358,7.5-7.5,7.5s-7.5-3.357-7.5-7.5c0-55.34,45.022-100.362,100.363-100.362c4.142,0,7.5,3.357,7.5,7.5
	s-3.358,7.5-7.5,7.5C193.087,167.647,154.794,205.94,154.794,253.01z M240.157,316.009c16.828,0,32.648-6.553,44.547-18.451
	c24.563-24.563,24.563-64.531,0-89.095c-11.899-11.898-27.719-18.451-44.547-18.451c-16.828,0-32.648,6.553-44.547,18.451
	c-24.563,24.563-24.563,64.531,0,89.095C207.508,309.456,223.329,316.009,240.157,316.009z M206.216,219.07
	c9.066-9.066,21.12-14.059,33.941-14.059c12.821,0,24.875,4.992,33.94,14.059c18.715,18.714,18.715,49.165,0,67.88
	c-9.066,9.066-21.12,14.059-33.94,14.059c-12.821,0-24.875-4.992-33.941-14.059C187.501,268.236,187.501,237.784,206.216,219.07z
	 M240.157,283.042c8.021,0,15.563-3.124,21.235-8.797c11.709-11.709,11.709-30.762,0-42.471c-5.672-5.672-13.213-8.796-21.235-8.796
	c-8.022,0-15.563,3.124-21.235,8.796c-11.708,11.709-11.709,30.762,0,42.472C224.593,279.918,232.135,283.042,240.157,283.042z
	 M229.528,242.382c2.839-2.84,6.614-4.403,10.629-4.403c4.015,0,7.79,1.563,10.628,4.403c5.861,5.86,5.861,15.396,0,21.257
	c-2.839,2.84-6.614,4.403-10.628,4.403c-4.015,0-7.79-1.563-10.628-4.402C223.667,257.778,223.667,248.242,229.528,242.382z
	 M543.252,26.442H7.5c-4.142,0-7.5,3.357-7.5,7.5v424.924v57.943c0,4.143,3.358,7.5,7.5,7.5h535.752c4.142,0,7.5-3.357,7.5-7.5
	v-57.943V33.942C550.752,29.8,547.394,26.442,543.252,26.442z M15,509.31v-42.943h452.075c4.142,0,7.5-3.357,7.5-7.5
	s-3.358-7.5-7.5-7.5H15V41.442h520.752v409.924h-14c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h14v42.943H15z M433.419,329.214
	h-4.046v-38.683c0-4.143-3.358-7.5-7.5-7.5s-7.5,3.357-7.5,7.5v38.683h-4.045c-4.142,0-7.5,3.357-7.5,7.5v46.184
	c0,4.143,3.358,7.5,7.5,7.5h4.045v38.683c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5v-38.683h4.046c4.142,0,7.5-3.357,7.5-7.5
	v-46.184C440.919,332.571,437.562,329.214,433.419,329.214z M425.919,375.397h-8.091v-8.092h8.091V375.397z M417.828,352.306v-8.092
	h8.091v8.092H417.828z M475.462,352.306h-4.046v-61.774c0-4.143-3.358-7.5-7.5-7.5s-7.5,3.357-7.5,7.5v61.774h-4.046
	c-4.142,0-7.5,3.357-7.5,7.5v46.183c0,4.143,3.358,7.5,7.5,7.5h4.046v15.592c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5v-15.592
	h4.046c4.142,0,7.5-3.357,7.5-7.5v-46.183C482.962,355.663,479.604,352.306,475.462,352.306z M467.962,398.488h-8.092v-8.091h8.092
	V398.488z M459.871,375.397v-8.092h8.092v8.092H459.871z M517.505,355.76c4.142,0,7.5-3.357,7.5-7.5v-46.183
	c0-4.143-3.358-7.5-7.5-7.5h-4.045v-4.046c0-4.143-3.358-7.5-7.5-7.5s-7.5,3.357-7.5,7.5v4.046h-4.046c-4.142,0-7.5,3.357-7.5,7.5
	v46.183c0,4.143,3.358,7.5,7.5,7.5h4.046v73.32c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5v-73.32H517.505z M501.914,340.76
	v-8.091h8.091v8.091H501.914z M501.914,309.577h8.091v8.092h-8.091V309.577z M116.964,77.851c0-13.345-10.856-24.201-24.201-24.201
	H51.344c-13.345,0-24.202,10.856-24.202,24.201s10.857,24.202,24.202,24.202h41.419C106.108,102.053,116.964,91.195,116.964,77.851z
	 M101.964,77.851c0,5.074-4.127,9.202-9.201,9.202h-19.04c1.172-2.839,1.822-5.945,1.822-9.202c0-3.256-0.65-6.363-1.822-9.201
	h19.04C97.837,68.649,101.964,72.777,101.964,77.851z M42.142,77.851c0-5.073,4.128-9.201,9.202-9.201s9.201,4.128,9.201,9.201
	c0,5.074-4.127,9.202-9.201,9.202S42.142,82.925,42.142,77.851z M234.855,258.311c-1.4-1.391-2.2-3.32-2.2-5.3
	c0-1.98,0.8-3.91,2.2-5.301c1.39-1.399,3.33-2.199,5.3-2.199c1.98,0,3.91,0.8,5.3,2.199c1.4,1.391,2.2,3.32,2.2,5.301
	c0,1.979-0.8,3.909-2.2,5.3c-1.39,1.399-3.32,2.2-5.3,2.2C238.186,260.511,236.246,259.71,234.855,258.311z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <Footer />
          </>
        )
      }}
    </StaticQuery>
  )
}
